import Head from 'next/head';
import { hexToTwVar } from '@ui/utils/color';
import { generatePalette } from '@ui/utils/pallete';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export var LearningGlobal = function LearningGlobal(_ref) {
  var color = _ref.color;
  return _jsx(Head, {
    children: _jsx("style", {
      children: "\n:root {\n".concat(generatePalette({
        value: color
      }).map(function (_ref2) {
        var hex = _ref2.hex,
          stop = _ref2.stop;
        return "--color-learning-".concat(stop, ": ").concat(hexToTwVar(hex), ";");
      }).join('\n'), "\n").concat(generatePalette({
        value: color
      }).map(function (_ref3) {
        var hex = _ref3.hex,
          stop = _ref3.stop;
        return "--color-learning-hex-".concat(stop, ": ").concat(hex, ";");
      }).join('\n'), "\n}")
    })
  });
};
export var getOrgStyles = function getOrgStyles(colors) {
  return "\n:root {\n--color-organization-background: ".concat(hexToTwVar(colors.background), ";\n--color-organization-logo-background: ").concat(hexToTwVar(colors.logo_background), ";\n--color-organization-main-feature: ").concat(hexToTwVar(colors.main_feature), ";\n--color-organization-secondary: ").concat(hexToTwVar(colors.secondary), ";\n--color-organization-secondary-feature: ").concat(hexToTwVar(colors.secondary_feature), ";\n").concat(generatePalette({
    value: colors.main
  }).map(function (_ref4) {
    var hex = _ref4.hex,
      stop = _ref4.stop;
    return "--color-organization-main-".concat(stop, ": ").concat(hexToTwVar(hex), ";");
  }).join('\n'), "\n").concat(generatePalette({
    value: colors.main
  }).map(function (_ref5) {
    var hex = _ref5.hex,
      stop = _ref5.stop;
    return "--color-organization-main-hex-".concat(stop, ": ").concat(hex, ";");
  }).join('\n'), "\n}");
};